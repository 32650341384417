import React from 'react';
import { withRouter } from 'react-router';
import BluveLayout from '../../../components/layout/BluveLayout';
import { Select, Table, Button, message, DatePicker, Radio, Switch, Tooltip, Icon } from "antd";
import { getEmpresas, buildReport, getVendedores, exportToExcel } from './ExtratoF';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import bluveStorage from '../../../components/localstorage/localstorage'

import "chartjs-plugin-datalabels";
import "chart.piecelabel.js";

import './Extrato.css'
import logToLoggly from 'components/Loggly/Loggly';
import EditarVendas from 'components/EditarVenda/EditarVendas';


const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY'
const { Option } = Select;

class ExtratoVendas extends React.Component {

  state = {
    empresas: [],
    empresaId: '',
    vendedor: {
      id: '',
      nome: '',
    },
    dataSelecionada: moment(),
    loading: false,
    periodo: {},
    alterouConfiguracaoRelatorio: false,
    data: [],
    showVendedoresSelect: false,
    totalPecas: 0,
    valorTotal: 'R$ 0,00',
    columns: [],
    opcaoExtrato: 'vendas',
    exibirSku: false,
    exibirEdicaoVendas: false,
  }

  vendedoresOptions = [];
  options = [];

  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onSelectVendedorChange = this.onSelectVendedorChange.bind(this);
    this.getVendedoresLocal = this.getVendedoresLocal.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.buildColumns = this.buildColumns.bind(this);
    this.onChangeOpcaoExtrato = this.onChangeOpcaoExtrato.bind(this);
    this.onChangeExibirSku = this.onChangeExibirSku.bind(this);
    this.editarRegistro = this.editarRegistro.bind(this);
    this.excluirRegistro = this.excluirRegistro.bind(this);
  }

  async componentDidMount() {
    const exibirSku = bluveStorage.get('exibirSku') !== 'false';
    this.setState({ exibirSku, loading: true });

    const empresas = await getEmpresas();
    this.setState({ empresas });

    for (const item of empresas) {
      this.options.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>);
    }

    let empresaId = bluveStorage.getReportEmpresaId();
    if (!empresaId && empresas.length > 0) {
      empresaId = empresas[0].key;
    }

    let periodo = bluveStorage.getReportPeriodo();
    if (!periodo) {
      periodo = {
        inicial: moment().subtract(7, "days"),
        final: moment(),
      };
    } else {
      periodo.inicial = moment(periodo.inicial);
      periodo.final = moment(periodo.final);
    }

    const listVendedores = await this.getVendedoresLocal(empresaId);
    if (listVendedores) {
      for (let v of listVendedores) {
        v.apelido = v.props ? v.props.children : v.apelido;
        this.vendedoresOptions.push(<Option key={v.key} value={v.key}>{v.nome}</Option>)
      }
      this.vendedoresOptions.push(<Option key='todos' value='todos'>Todos</Option>);
    }

    this.buildColumns();
    this.setState({
      empresaId,
      periodo,
      loading: false,
    });
  }

  buildColumns() {

    let columns = [
      // {
      //   title: 'Id',
      //   dataIndex: 'key',
      //   key: 'key',
      // },

      {
        title: 'Início Atendimento',
        dataIndex: 'dataInicial',
        key: 'dataInicial',
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: 'Fim Atendimento',
        dataIndex: 'dataFinal',
        key: 'dataFinal',
        sorter: true,
        sortDirections: ["descend", "ascend"],
      }];

    if (this.state.vendedor.id === 'todos') {
      columns.push({
        title: 'Vendedor',
        dataIndex: 'vendedor',
        key: 'vendedor',
        sorter: true,
        sortDirections: ["descend", "ascend"],
      })
    }

    if (this.state.opcaoExtrato === 'todas') {
      columns.push({
        title: 'Vendeu',
        dataIndex: 'vendeu',
        key: 'vendeu',
        sorter: true,
        sortDirections: ["descend", "ascend"],
      })
    }


    columns = columns.concat([
      {
        title: 'Fuga',
        dataIndex: 'fuga',
        key: 'fuga',
        sorter: true,
        sortDirections: ["descend", "ascend"],
      }
    ]);

    if (this.state.opcaoExtrato !== 'perdas') {
      columns = columns.concat([
        {
          title: 'Venda por Integração',
          dataIndex: 'integracao',
          key: 'integracao',
          sorter: true,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: 'Id Venda',
          dataIndex: 'vendaId',
          key: 'vendaId',
          sorter: true,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: 'Peças vendidas',
          dataIndex: 'totalPecas',
          key: 'totalPecas',
          align: 'right',
          sorter: true,
          sortDirections: ["descend", "ascend"],
        },
      ]);
    }

    if (this.state.opcaoExtrato !== 'vendas') {
      columns = columns.concat([
        {
          title: 'Experimentou',
          dataIndex: 'experimentou',
          key: 'experimentou',
          sorter: true,
          sortDirections: ["descend", "ascend"],
        }]);

      columns = columns.concat([
        {
          title: 'Motivos',
          dataIndex: 'motivos',
          key: 'motivos',
          sorter: true,
          sortDirections: ["descend", "ascend"],
          mapValue: motivos => motivos ? motivos.map(motivos => motivos.nome).join(', ') : '',
          render: motivos => motivos ? motivos.map(motivos => motivos.nome).join(', ') : '',
        }]);
    }

    columns = columns.concat([
      {
        title: 'Produtos',
        dataIndex: 'produtos',
        key: 'produtos',
        sorter: true,
        sortDirections: ["descend", "ascend"],
        mapValue: produtos => produtos.map(produto =>
          this.state.exibirSku &&

          produto.codigo ? produto.codigo :
          produto.pdvId ? produto.pdvId : 
          produto.nome).join(', '),

        render: produtos => produtos.map(produto =>
          this.state.exibirSku &&

          produto.codigo ? produto.codigo :
          produto.pdvId ? produto.pdvId : 
          produto.nome).join(', '),
      }]);

    if (this.state.opcaoExtrato !== 'perdas') {

      columns.push({
        title: 'Troca',
        dataIndex: 'troca',
        key: 'troca',
        align: 'center',
        sorter: true,
        sortDirections: ["descend", "ascend"],
        mapValue: troca => (troca === 1 ? 'Sim' : 'Não'),
        render: troca => troca ? 'Sim' : 'Não',
      });
  

      columns.push({
        title: 'Valor',
        dataIndex: 'valorTotal',
        key: 'valorTotal',
        align: 'right',
        sorter: true,
        sortDirections: ["descend", "ascend"],
      });
    }


    // columns.push({
    //     title: "",
    //     width: "auto",
    //     key: "action",
    //     render: (text, record) => (
    //       <span>
    //         <Tooltip placement="topLeft" title={`Editar ${record.vendeu === 'Sim' ? 'venda' : 'perda'}`}>
    //           <a onClick={() => this.editarRegistro(record)}>
    //             <Icon type="edit" />
    //           </a>
    //         </Tooltip>
    //         <span className="ant-divider" />

    //         <Tooltip placement="topLeft" title={`Excluir ${record.vendeu === 'Sim' ? 'venda' : 'perda'}`}>
    //           <a onClick={() => this.excluirRegistro(record)}>
    //             <Icon type="delete" />
    //           </a>
    //         </Tooltip>

    //       </span>
    //     ),
    // });

    this.setState({ columns });

  }

  onChangeOpcaoExtrato(e) {
    this.setState({
      opcaoExtrato: e.target.value,
      totalPecas: 0,
      valorTotal: 'R$ 0,00',
      data: [],
    });

    setTimeout(() => {
      this.buildColumns();
    }, 500);
  }

  onChangeExibirSku(checked) {
    this.setState({ exibirSku: checked });
    bluveStorage.set('exibirSku', checked);
  }

  editarRegistro(record) {
    // if (record.vendeu === 'Sim') {
    //   this.setState({ exibirEdicaoVendas: true,registroEdicao: record });
    // }
  }

  excluirRegistro(record) {
    alert(`Registro ${record.key} não pode ser excluído!`);
    console.log(record);


  }

  onExportClick() {
    const empresaFiltrada = this.state.empresas.filter(item => item.key === this.state.empresaId)[0];
    
    // , empresaNome, vendedor, periodo, totalPecas, valorTotal, opcaoExtrato, colunas
    const props = {
      data: this.state.data,
      empresaNome: `${empresaFiltrada.razaoSocial} (${empresaFiltrada.nomeFantasia})`,
      vendedor: this.state.vendedor,
      periodo: this.state.periodo,
      totalPecas: this.state.totalPecas,
      valorTotal: this.state.valorTotal,
      opcaoExtrato: this.state.opcaoExtrato,
      colunas: this.state.columns
    }
    
    exportToExcel(props);
  }

  onDateChange(value) {
    const periodo = {
      inicial: value[0],
      final: value[1]
    }
    this.setState({ periodo, alterouConfiguracaoRelatorio: true });
  }

  onSelectVendedorChange(value, object) {
    const vendedor = {
      id: value,
      nome: object.props.children
    }
    this.setState({ vendedor });
    setTimeout(() => {
      this.buildColumns();
    }, 500);

  }

  async getVendedoresLocal(empresaId) {
    const empresas = this.state.empresas;

    if (!empresas.length > 0) {
      return;
    }

    const empresaIndex = empresas.findIndex((empresa) => empresa.key === empresaId);
    let vendedores = [];

    if (!empresaIndex || !empresas[empresaIndex].vendedores) {
      vendedores = (await getVendedores(empresaId)).filter(v => v.ativo === true);
      empresas[empresaIndex].vendedores = vendedores;
    } else {
      vendedores = empresas[empresaIndex].vendedores.filter(v => v.ativo === true);
    }

    if (vendedores !== undefined) {
      vendedores.sort((a, b) => a.nome.localeCompare(b.nome));
    }
    return vendedores;
  }

  async onSelectChange(value) {
    this.vendedoresOptions = [];

    if (value !== 'todas') {
      this.setState({ alterouConfiguracaoRelatorio: true });
    }

    const vendedores = await this.getVendedoresLocal(value);
    if (vendedores) {
      for (const item of vendedores) {
        this.vendedoresOptions.push(<Option key={item.key} value={item.key}>{item.nome}</Option>)
      }
      this.vendedoresOptions.push(<Option key='todos' value='todos'>Todos</Option>);
    }

    this.setState({
      empresaId: value,
      showCheckBox: value === 'todas',
      checked: value === 'todas',
      vendedor: {}
    });
  }

  async onFilterClick() {

    if (!this.state.vendedor.id) {
      message.warning("Selecione um vendedor.");
      return;
    }

    if (!this.state.periodo.inicial || !this.state.periodo.final) {
      message.warning("Selecione o período para gerar o gráfico.");
      return;
    }

    this.setState({ loading: true });

    const props = {
      vendedorId: this.state.vendedor.id,
      empresaId: this.state.empresaId,
      dataInicial: this.state.periodo.inicial,
      dataFinal: this.state.periodo.final,
      opcaoExtrato: this.state.opcaoExtrato,
      exibirSku: this.state.exibirSku
    }

    const data = await buildReport(props);

    this.setState({
      totalPecas: data.totalPecas,
      valorTotal: data.valorTotal,
      data: data.dados,
      loading: false,
      showVendedoresSelect: true
    });
    bluveStorage.setReportPeriodo(this.state.periodo);
  }

  onTableHandleChange = (pagination, filters, sorter) => {

    const columnSortMap = {
      dataInicial: 'dataInicial',
      dataFinal: 'dataFinal',
      vendedor: 'vendedor',
      vendeu: 'vendeu',
      fuga: 'fuga',
      interacao: 'interacao',
      totalPecas: 'totalPecas',
      valorTotal: 'valorTotal',
      experimentou: 'experimentou',
      produtos: 'produtos',
      motivos: 'motivos',
      troca: 'troca',
    };
    const tableData = this.state.data.slice();
    const { columnKey, order } = sorter;
    if (columnKey) {
      const field = columnSortMap[columnKey];
      const sortOrder = order === 'descend' ? -1 : 1;

      try {
        tableData.sort((a, b) =>
          a[field].localeCompare(b[field], undefined, { numeric: true }) * sortOrder);
      } catch (error) {
        message.error(`Erro ao ordenar pela coluna ${columnKey}`);
        return;
      }
    }
    this.setState({ data: tableData });
  }


  render() {
    return (
      <BluveLayout selectItem={"relatorio/extrato-atendimentos"}>

        {this.state.exibirEdicaoVendas && (
          <EditarVendas
            visible={this.state.exibirEdicaoVendas}
            registroEdicao={this.state.registroEdicao}
            //onOk={() => this.setState({ exibirEdicaoVendas: false })}
            onCancel={() => this.setState({ exibirEdicaoVendas: false })}
            />
        )}

        <div className="containerExtratoAtendimentos">
          <div className="child">
            <label>Empresa: </label>
            <br></br>
            <Select
              disabled={this.state.loading}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "200px" }}
              placeholder='Selecione uma empresa'
              onChange={this.onSelectChange}
              value={this.state.empresaId}
            >
              {this.options}
            </Select>

          </div>
          <div className="child">
            <label>Vendedor: </label>
            <br></br>
            <Select
              disabled={this.state.loading || this.vendedoresOptions.length === 0}
              showSearch
              optionFilterProp="children"
              style={{ width: '250px' }}
              placeholder='Selecione um vendedor'
              onChange={this.onSelectVendedorChange}
              value={this.state.vendedor.nome}>
              {this.vendedoresOptions}
            </Select>
          </div>
          <div className="child">
            <label>Período: </label>
            <br></br>
            <div className="rangePickerDuplo">
              <RangePicker
                style={{ width: '100%' }}
                locale={locale}
                disabled={this.state.loading}
                format={dateFormat}
                placeholder={['Data Inicial', 'Data Final']}
                onChange={this.onDateChange}
                value={[this.state.periodo.inicial, this.state.periodo.final]}
              />
            </div>
          </div>
          <div className="child">
            <label>Opções: </label>
            <br></br>
            <Radio.Group onChange={this.onChangeOpcaoExtrato} value={this.state.opcaoExtrato}>
              <Radio style={{ display: 'block' }} value={'vendas'}>Vendas</Radio>
              <Radio style={{ display: 'block' }} value={'perdas'}>Perdas</Radio>
              <Radio style={{ display: 'block' }} value={'todas'}>Todas</Radio>
            </Radio.Group>
          </div>
          <div className="child">
            <Button type='primary' disabled={this.state.loading} onClick={this.onFilterClick}>Filtrar</Button>
            <br></br>
            <br></br>
            <Button
              className='excel-button-v2'
              disabled={this.state.loading || this.state.data.length === 0}
              onClick={this.onExportClick}
              style={{ marginLeft: '1rem' }}
            >
              Exportar para Excel
            </Button>

            <br></br>
            <Switch
              checkedChildren="Sku do produto"
              unCheckedChildren="Nome do produto"
              checked={this.state.exibirSku}
              onChange={this.onChangeExibirSku}
            />
          </div>
        </div>

        <div style={{ marginTop: 20 }}>

          <Table
            pagination={{
              defaultPageSize: 100,
              position: 'both',
              pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
              showSizeChanger: true,
              locale: { items_per_page: "" }
            }}
            columns={this.state.columns}
            dataSource={this.state.data}
            bordered
            loading={this.state.loading}
            onChange={this.onTableHandleChange}
          />

          <br></br>
          <p><strong>Registros: </strong>{this.state.data.length}</p>
          <p><strong>Peças: </strong>{this.state.totalPecas}</p>
          <p><strong>Valor Total: </strong>{this.state.valorTotal}</p>
        </div>

      </BluveLayout>
    )
  }
}

export default withRouter(ExtratoVendas);
